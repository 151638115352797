import { v4 as uuidv4 } from 'uuid';
import { Filesystem, Directory } from '@capacitor/filesystem';
import * as Sentry from '@sentry/browser';

import { isAndroid } from '../../utils/platform';
import logEvent from '../../utils/logger';

const TEMP_TOKEN_FILE_NAME = 's2.t';

const checkIfFileExists = async () => {
  try {
    // Attempt to read the file
    await Filesystem.readFile({
      path: TEMP_TOKEN_FILE_NAME,
      directory: isAndroid ? Directory.Data : Directory.Documents,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const checkAndPersistTempToken = async (userDoc) => {
  try {
    const fileExists = await checkIfFileExists();
    if (fileExists) {
      return;
    }
    const tempToken = uuidv4();
    await userDoc.updateFields({ tempToken });
    await Filesystem.writeFile({
      path: TEMP_TOKEN_FILE_NAME,
      data: btoa(JSON.stringify({ tempToken, userId: userDoc.id }), 'base64'),
      directory: isAndroid ? Directory.Data : Directory.Documents,
    });
    logEvent('tempTokenGenerated');
  } catch (error) {
    Sentry.captureException(error);
    logEvent('tempTokenGenerationFailed', {
      error: error.message,
    });
  }
};

const checkAndDeleteTempToken = async () => {
  try {
    const fileExists = await checkIfFileExists();
    if (!fileExists) {
      return;
    }
    await Filesystem.deleteFile({
      path: TEMP_TOKEN_FILE_NAME,
      directory: isAndroid ? Directory.Data : Directory.Documents,
    });
    logEvent('tempTokenDeleted');
  } catch (error) {
    Sentry.captureException(error);
    logEvent('tempTokenDeletionFailed', {
      error: error.message,
    });
  }
};

export {
  checkAndPersistTempToken,
  checkAndDeleteTempToken,
};

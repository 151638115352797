import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import format from 'string-template';

import useSessionStore from '../../../hooks/useSessionStore';
import FirebaseContext from '../../../context/FirebaseContext';
import { getQueryVariable } from '../../../utils/queryParams';
import SendFeedbackSection from './SendFeedbackSection';
import texts from './texts.json';

const SendFeedbackSectionContainer = ({
  latestGameplaySession,
  activityFeedbacks,
}) => {
  const [notificationText, setNotificationText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const {
    authUser: {
      uid: loggedInCoachId,
    },
  } = useSessionStore();

  const isFeedbackSent = !!latestGameplaySession.feedbackSendTime;
  const buttonText = isFeedbackSent
    ? texts.sendFeedbackAgain
    : texts.sendFeedbackToClient;

  useEffect(() => {
    let text = latestGameplaySession.coachFeedback ? `${latestGameplaySession.coachFeedback}\n\n` : '';
    activityFeedbacks.forEach((feedback) => {
      text = feedback.coachFeedback ? `${text}${feedback.name}\n${feedback.coachFeedback}\n\n` : text;
    });
    setNotificationText(text);
  }, [
    activityFeedbacks,
    latestGameplaySession.coachFeedback,
  ]);

  const onFeedbackSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const remoteCallPayload = {
      userId: latestGameplaySession.user,
      feedbackSummary: `[Coach Feedback]\n${notificationText}`,
      gameplaySessionId: latestGameplaySession.id,
      coachId: getQueryVariable('coachId') || loggedInCoachId,
    };

    const response = await remote('feedbackSummary/sendFeedbackToClient', remoteCallPayload);

    if (response.ok) {
      await latestGameplaySession.updateFields({
        feedbackSendTime: Date.now(),
      });
    } else {
      Sentry.captureException(new Error('Error sending feedbacks to client'), {
        extra: {
          userId: latestGameplaySession.user,
          gameplaySessionId: latestGameplaySession.id,
          response,
        },
      });
    }
    setIsLoading(false);
  };

  const formatFeedbackTime = (feedbackTime) => {
    if (feedbackTime > 0) {
      const timestamp = new Date(feedbackTime);
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      }).format(timestamp);
    }
    return '';
  };

  const message = latestGameplaySession.feedbackSendTime
    ? format(texts.feedbackSentMessage, formatFeedbackTime(latestGameplaySession.feedbackSendTime))
    : '';

  return !!notificationText && (
    <SendFeedbackSection
      message={message}
      buttonText={buttonText}
      isLoading={isLoading}
      isFeedbackSent={isFeedbackSent}
      onFeedbackSubmit={onFeedbackSubmit}
    />
  );
};

SendFeedbackSectionContainer.propTypes = {
  latestGameplaySession: PropTypes.object.isRequired,
  activityFeedbacks: PropTypes.array.isRequired,
};

export default SendFeedbackSectionContainer;
